import { Controller } from "@hotwired/stimulus";
import { get } from "@rails/request.js";

export default class extends Controller {
  static values = { url: String };

  connect() {
    console.log("connected");
    setTimeout(() => {
      get(this.urlValue, {
        responseKind: "turbo-stream",
      });
    }, 3000);
  }
}
